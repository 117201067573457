.contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageContainer {
    padding: 0 24px;
}

.cardContainer {
    min-width: 200px;
    width: 900px;
}

.cardContent {
    display: flex;
    flex-direction: row;
    gap: 18px;
    justify-content: space-between;
    padding: 0;
}

.content {
    width: 300px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    gap: 18px;
}

.menuUrl {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
}

.headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerActions {
    display: flex;
    gap: 8px;
}

@media (max-width: 961px) {
    .menuUrl {
        width: 360px;
    }

    .content, .cardContent {
        display: flex;
        flex-direction: column-reverse;
        gap: 18px;
        justify-content: center;
        align-items: center;
    }

    .content {
        width: 100%;
        flex-direction: column;
    }
}

@media (min-width: 961px) and (max-width: 1250px) {
    .menuUrl{
        width: 300px;
    }

    .cardContainer {
        width: 600px;
        padding: 8px;
    }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
/* big landscape tablets, laptops, and desktops */
/* hi-res laptops and desktops */
@media (min-width: 642px) and (max-width: 961px) {

    .cardContainer {
        width: 450px;
        padding: 8px;
    }
}


@media (max-width: 460px) {
    .menuUrl {
        width: 360px;
    }

    .contentContainer {
        flex-direction: column;
        padding: 4px;
    }

    .headerContent {
        flex-direction: column-reverse;
    }

    .headerActions {
        justify-content: space-between;
        width: 100%;
    }
}

/* smartphones, iPhone, portrait 480x320 phones */
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 460px) and (max-width: 641px) {
    .menuUrl {
        width: 270px;
    }

    .cardContainer {
        width: 350px;
        padding: 2px;
    }
}

@media (min-width: 381px) and (max-width: 460px) {
    .menuUrl {
        width: 220px;
        font-size: 12px;
    }

    .cardContainer {
        width: 300px;
    }
}


@media (max-width: 380px) {
    .menuUrl {
        width: 160px;
        font-size: 12px;
    }

    .cardContainer {
        width: 250px;
    }
}


@media (max-width: 320px) {
    .menuUrl {
        width: 130px;
        font-size: 12px;
    }

    .cardContainer {
        width: 220px;
    }

    .contentContainer {
        display: none;
    }
}