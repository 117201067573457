.contentContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    width: fit-content;
}

.cardContainer {
    padding: 0 24px;
}


.input {
    border-color: #D8DADC;
    min-width: 300px;
    height: 40px;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
}