.menusContainer {
    padding: 0 24px;
    /*min-width: 300px;*/
    /*width: fit-content;*/
    gap: 36px;
    display: flex;
    flex-direction: column;
}

.menuActions {
    display: flex;
    min-width: fit-content;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
}

.menusList {
    display: flex;
    /*place-content: flex-start;*/
    place-content: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 36px;
}