
.input {
    border-color: #D8DADC;
    width: 300px;
    height: 40px;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
}

input {
    text-align: start;
    padding: 0 10px;
}


@media (min-width: 320px) and (max-width: 400px) {
    .input {
        width: 250px;
    }
}

@media (max-width: 320px) {
    .input {
        width: 200px;
    }
}