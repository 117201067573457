.menusContainer {
    min-width: 200px;
    width: fit-content;
    gap: 36px;
    display: flex;
    flex-direction: column;
    padding: 24px;
}


.cardContainer {
    padding: 0 24px;
}