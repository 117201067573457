.buttonContainer {
    min-width: 300px;
    color: white;
    background-color: #da291c;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    line-height: 1;
    transition: 0.3s;
}


.buttonContainer:hover {
    opacity: .7;
}


@media (min-width: 320px) and (max-width: 400px) {
    .buttonContainer {
        min-width: 250px;
    }
}

@media (max-width: 320px) {
    .buttonContainer {
        min-width: 200px;
    }
}