.cardContainer {
    width: 300px;
}


@media (max-width: 320px) {
    .cardContainer {
        width: 250px;
    }
}


iframe {
    width:500px;
    height: 500px;
    overflow-y: hidden;
    clip-path: inset(5px 0px 0px 5px);
}