.sidebarContainer {
    display: flex;
    z-index: 3;
    color: black;
    box-shadow: 0 0 5px 0 rgba(239, 239, 239, 0.94);
    clip-path: inset(0px 0px 0px 0px);
}

.sidebarToggle {
    position: absolute;
    right: 20px;
    top: 20px;
}

.contentContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 0 24px 0;
}

@media (max-width: 500px) {
    .sidebarContainer {
        clip-path: none;
    }
}