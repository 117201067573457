.contentContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    width: fit-content;
}

.cardContainer {
    width: 100%;
    padding: 0 24px;
}

.plans {
    display: flex;
}

.cardContent {
    justify-content: center;
    gap: 48px;
    display: flex;
    flex-wrap: wrap-reverse;
}

.cardPlan {
    display: flex;
    width: 190px;
    min-height: 400px;
    height: 100%;
    padding: 24px 32px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    border-color: #D8DADC;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
}

p {
    margin: 1px;
    font-size: 14px;
}

@media (max-width: 320px) {
    .cardPlan {
        width: 150px;
    }
}