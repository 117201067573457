.AppLogo {
    height: 4vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .AppLogo {
        animation: AppLogo-spin infinite 20s linear;
    }
}

.AppHeader {
    /*position: fixed;*/
    top: 0;
    z-index: 3;
    /*width: 100%;*/
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: black;
    /*box-shadow: 0 0 5px 0 rgba(239, 239, 239, 0.94);*/
    border-color: #efefef;
    border-width: 1px;
    border-style: solid;
}

.LogoContainer {
    width: 200px;
    align-items: center;
    display: inline-flex;
    padding: 0 2%;
}

.AppLink {
    color: #61dafb;
}

@keyframes AppLogo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
